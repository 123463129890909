"use strict";

import React from "react";
import { unit_map } from "../../util/unit_map";

export default function QuantityWidget(props) {
    let units;

    const changeUnit = (e) => {
        props.onChangeUnit(e.target.value);
        props.onChangeType(e.target.options[e.target.selectedIndex].dataset.type);
    }

    if (typeof props.unit_type !== 'undefined') {
        units = unit_map[props.unit_type].map(({ name, value }) => {
            return (
                <option key={value} data-type={props.unit_type} value={value}>{name}</option>
            );
        });
    } else {
        units = Object.keys(unit_map).map((type) => {
            return (
                <optgroup key={type} label={type}>
                    {unit_map[type].map(({ name, value }) => {
                        return (
                            <option key={value} data-type={type} value={value}>{name}</option>
                        );
                    })}
                </optgroup>
            );
        })
    }
    return (
        <div className="input-group mb-3">
            <input type="number" className="form-control" id="quantity-amount" onChange={props.onChangeAmount} required={true} />
            <select className="form-select" id="quantity-unit" onChange={changeUnit} required={true}>
                <option></option>
                {units}
            </select>
        </div>
    );
}
