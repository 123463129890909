"use strict";

import React from "react";
import PropTypes from 'prop-types';
import { Tab, Tabs } from "react-bootstrap";

function NutritionsDisplay({ nutritions }) {
    return (
        <Tabs className="mb-3">
            {nutritions.map(({ id, calories, fat, sugar, protein, carbohydrates, fibre, quantity }) => {
                return (
                    <Tab eventKey={id} title={quantity.label} className="container">
                        <dl className="row">
                            <dt className="col-6">Calories</dt>
                            <dd className="col-6">{calories} kCal</dd>
                            <dt className="col-6">Fat</dt>
                            <dd className="col-6">{fat.toFixed(1)} g</dd>
                            <dt className="col-6">Sugar</dt>
                            <dd className="col-6">{sugar.toFixed(1)} g</dd>
                            <dt className="col-6">Protein</dt>
                            <dd className="col-6">{protein.toFixed(1)} g</dd>
                            <dt className="col-6">Carbohydrates</dt>
                            <dd className="col-6">{carbohydrates.toFixed(1)} g</dd>
                            <dt className="col-6">Fibre</dt>
                            <dd className="col-6">{fibre.toFixed(1)} g</dd>
                        </dl>
                    </Tab>
                );
            })}
        </Tabs>
    );
}

NutritionsDisplay.propTypes = {
    nutritions: PropTypes.arrayOf(PropTypes.object),
}

export default NutritionsDisplay;
