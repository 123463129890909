"use strict";

import { gql, useQuery } from "@apollo/client";
import React from "react";
import Select from "react-select";

export default function FoodCategorySearch(props) {

    const { loading, error, data } = useQuery(gql`
        query foodCategories {
            foodCategories {
                id
                name
            }
        }
    `);

    if (error) return 'Error :(';

    return (
        <Select
            defaultOptions
            isLoading={loading}
            options={data?.foodCategories.map(({ id, name }) => ({ label: name, value: id })) || []}
            isClearable={true}
            placeholder="Food category"
            onChange={props.onChange} />
    );
}
