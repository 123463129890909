"use strict";

import { client } from "../../util/client";
import { gql } from "@apollo/client";
import React, { useCallback, useState } from "react";
import AsyncSelect from 'react-select/async';
import { debounce } from "debounce";

export default function MealSelect(props) {
    const [search, setSearch] = useState("");

    const getAsyncOptions = async (input) => {
        return client.query({
            query: gql`
                query meals($search: String) {
                    meals(search: $search) {
                        id
                        name
                    }
                }
            `, variables: { search: input }
        }).then((response) => {
            return response.data.meals.map((meal) => ({
                value: meal.id,
                label: meal.name,
            }));
        });
    };

    const loadOptions = useCallback(debounce((inputText, callback) => {
        getAsyncOptions(inputText).then((options) => callback(options));
    }, 500), []);

    return (
        <AsyncSelect
            inputValue={search}
            onInputChange={(value) => setSearch(value)}
            loadOptions={loadOptions}
            defaultValue={[]}
            defaultOptions={[]}
            onChange={props.onChange} />
    );
}
