"use strict";

import { gql, useQuery } from "@apollo/client";
import React from "react";
import Select from "react-select";

export default function GroupSelect(props) {

    const { loading, error, data } = useQuery(gql`
        query groups {
            groups {
                id
                name
            }
        }
    `);

    if (error) return 'Error :(';

    return (
        <Select
            id={props.id}
            defaultOptions
            isLoading={loading}
            options={data?.groups.map(({ id, name }) => ({ label: name, value: id })) || []}
            onChange={props.onChange} />
    );
}
