"use strict";

import React from "react";
import PropTypes from 'prop-types';
import Search from "../generic/_search";
import MealTypeSearch from "../meal_type/_search";

function MealFilter({ refetch }) {
    return (
        <div className="row">
            <div className="col">
                <Search refetch={refetch} />
            </div>
            <div className="col">
                <MealTypeSearch onChange={(e) => refetch({ type: e?.value || null })} />
            </div>
        </div>
    );
}

MealFilter.propTypes = {
   refetch: PropTypes.func.isRequired
};

export default MealFilter;
