"use strict";

import { gql, useMutation } from "@apollo/client";
import React from "react";

export default function MealPlanItemRemove(props) {
    const [removeMealPlanItem] = useMutation(gql`
        mutation removeMealPlanItem($id: ID!) { removeMealPlanItem(id: $id) }
    `, {
        update(cache, data) {
            const normalizedId = cache.identify({ id: data.data.removeMealPlanItem, __typename: 'MealPlanItem' });
            cache.evict({ id: normalizedId });
            cache.gc();
        }
    });

    const handleRemove = (e) => {
        e.preventDefault();
        if (confirm('Are you sure you want to delete this meal plan item?')) {
            removeMealPlanItem({ variables: { id: props.id } });
        }
        props.onRemove(e);
    };

    return (
        <a href="#" className="btn btn-danger" onClick={handleRemove}>
            <i className="bi bi-trash"></i>
        </a>
    );
}
