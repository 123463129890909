"use strict";

import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import FoodRemove from "./_remove";
import errorHandler from "../../util/error_handler";
import FoodFilter from "./_filter";
import FoodLabel from "./_label";

export default function FoodTable() {
    const [first_result, setFirstResult] = useState(0);
    const { loading, error, data, refetch } = useQuery(gql`
        query foods(
            $search: String,
            $max_results: Int,
            $first_result: Int,
            $category: ID
        ) {
            foods(
                search: $search,
                max_results: $max_results,
                first_result: $first_result,
                category: $category
            ) {
                id
                name
                category {
                    id
                    name
                    color
                }
                nutrition {
                    id
                    calories
                    fat
                    sugar
                    carbohydrates
                    fibre
                    protein
                    quantity {
                        id
                        amount
                        unit
                        label
                    }
                }
            }
        }`
    );

    if (loading) return <p>Loading...</p>;
    if (error) return errorHandler(error);

    return (
        <div>
            <div className="mb-3">
                <FoodFilter refetch={refetch} />
            </div>
            <div className="table-responsive card">
                <table className="table table-borderless table-striped table-hover mb-0">
                    <thead className="border-bottom">
                        <tr>
                            <th>Food</th>
                            <th>Category</th>
                            <th>Amount</th>
                            <th>Calories</th>
                            <th>Fat (g)</th>
                            <th>Protein (g)</th>
                            <th>Fibre (g)</th>
                            <th>Carbs (g)</th>
                            <th>Sugar (g)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.foods.map(({id, name, nutrition, category}) => (
                            <tr key={id}>
                                <td>
                                    <FoodRemove id={id} />
                                    <FoodLabel
                                        id={id}
                                        name={name}
                                        nutrition={nutrition}
                                        category={category} />
                                </td>
                                <td><span className={"me-2 badge rounded-pill bg-" + category?.color}>{category?.name}</span></td>
                                <td>{nutrition[0]?.quantity.label }</td>
                                <td>{nutrition[0]?.calories || null}</td>
                                <td>{nutrition[0]?.fat.toFixed(1) || null}</td>
                                <td>{nutrition[0]?.protein.toFixed(1) || null}</td>
                                <td>{nutrition[0]?.fibre.toFixed(1) || null}</td>
                                <td>{nutrition[0]?.carbohydrates.toFixed(1) || null}</td>
                                <td>{nutrition[0]?.sugar.toFixed(1) || null}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav aria-label="Food pager">
                <ul className="pagination mt-3">
                    <li className={"page-item " + (first_result == 0 ? "disabled" : "")}>
                        <a className="page-link" href="#" onClick={(e) => {
                            e.preventDefault();
                            refetch({ first_result: first_result - 15 });
                            setFirstResult(first_result - 15);
                        }}>Previous</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" href="#" onClick={(e) => {
                            e.preventDefault();
                            refetch({ first_result: first_result + 15 });
                            setFirstResult(first_result + 15);
                        }}>Next</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
