"use strict";

import React from "react";
import { Helmet } from "react-helmet";
import NavBarComponent from "../components/generic/_navbar";
import GroceryLists from "../components/grocery_list";

export default function GroceryListPage() {
    return (
        <>
            <Helmet>
                <title>Grocery Lists</title>
            </Helmet>
            <NavBarComponent />
            <div className="container">
                <GroceryLists />
            </div>
        </>
    );
}
