"use strict";

import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import MealFilter from "./_filter";
import MealLabel from "./_label";
import MealRemove from "./_remove";

export default function MealTable() {
    const [first_result, setFirstResult] = useState(0);
    const { loading, error, data, refetch } = useQuery(gql`
        query meals(
            $search: String,
            $max_results: Int,
            $first_result: Int,
            $type: ID
        ) {
            meals(
                search: $search,
                max_results: $max_results,
                first_result: $first_result,
                type: $type
            ) {
                id
                name
                preparation_time
                servings
                directions
                nutrition {
                    id
                    calories
                    carbohydrates
                    fat
                    sugar
                }
                ingredients {
                    id
                    food {
                        id
                        name
                    }
                    quantity {
                        id
                        unit
                        amount
                    }
                }
                types {
                    id
                    name
                    color
                }
            }
        }`
    );

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div>
            <div className="mb-3">
                <MealFilter refetch={refetch} />
            </div>
            <div className="table-responsive card">
                <table className="table table-borderless table-striped table-hover mb-0">
                    <thead className="border-bottom">
                        <tr>
                            <th>Name</th>
                            <th>Prep. time (min)</th>
                            <th>Servings</th>
                            <th>Calories</th>
                            <th>Fat (g)</th>
                            <th>Sugar (g)</th>
                            <th>Carbs (g)</th>
                            <th>Type(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.meals.map(({id, name, types, preparation_time, servings, nutrition, directions, ingredients}) => (
                            <tr key={id}>
                                <td>
                                    <MealRemove id={id} />
                                    <MealLabel
                                        name={name}
                                        preparation_time={preparation_time}
                                        directions={directions}
                                        ingredients={ingredients}
                                        nutrition={nutrition}
                                        servings={servings} />
                                </td>
                                <td>{preparation_time}</td>
                                <td>{servings}</td>
                                <td>{nutrition?.calories || null}</td>
                                <td>{nutrition?.fat.toFixed(1) || null}</td>
                                <td>{nutrition?.sugar.toFixed(1) || null}</td>
                                <td>{nutrition?.carbohydrates.toFixed(1) || null}</td>
                                <td>
                                    {types?.map(({ id, name, color }) => (
                                        <span className={"me-2 badge rounded-pill bg-" + color} key={id}>{name}</span>
                                    )) || null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav aria-label="Meal pager">
                <ul className="pagination mt-3">
                    <li className={"page-item " + (first_result == 0 ? "disabled" : "")}>
                        <a className="page-link" href="#" onClick={(e) => {
                            e.preventDefault();
                            refetch({ first_result: first_result - 15 });
                            setFirstResult(first_result - 15);
                        }}>Previous</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" href="#" onClick={(e) => {
                            e.preventDefault();
                            refetch({ first_result: first_result + 15 });
                            setFirstResult(first_result + 15);
                        }}>Next</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
