"use strict";

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FoodPage from "../page/food";
import GroceryListPage from "../page/grocery_list";
import Home from "../page/home";
import MealPage from "../page/meal";
import MealPlanPage from "../page/meal_plan";
import NotFound from "../page/not_found";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Home />} />
                <Route path="/food/" element={<FoodPage />} />
                <Route path="/meal/" element={<MealPage />} />
                <Route path="/meal-plan/" element={<MealPlanPage />} />
                <Route path="/grocery-list/" element={<GroceryListPage />} />
            </Routes>
        </BrowserRouter>
    );
}
