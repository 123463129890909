"use strict";

import React from "react";
import { Helmet } from "react-helmet";
import NavBarComponent from "../components/generic/_navbar";
import MealPlans from "../components/meal_plan";

export default function MealPlanPage() {
    return (
        <>
            <Helmet>
                <title>Meal Plans</title>
            </Helmet>
            <NavBarComponent />
            <div className="container">
                <MealPlans />
            </div>
        </>
    );
}
