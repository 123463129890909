"use strict";

import { gql, useMutation } from "@apollo/client";
import React from "react";

export default function GroceryListItem(props) {
    const [toggleChecked, { loading, error }] = useMutation(gql`
        mutation toggleGroceryListItem($id: ID!) {
            toggleGroceryListItem(id: $id) {
                id
                checked
                auto_added
                food {
                    id
                    name
                }
                quantity {
                    id
                    amount
                    type
                    unit
                }
            }
        }
    `);

    let label = null;
    if (props.item.checked) {
        label = (
            <s className="text-muted">{props.item.quantity.amount} {props.item.quantity.unit} {props.item.food.name}</s>
        );
    } else {
        label = (
            <span>{props.item.quantity.amount} {props.item.quantity.unit} {props.item.food.name}</span>
        );
    }

    return (
        <div className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                checked={props.item.checked}
                id={props.item.id}
                onChange={(e) => {
                    toggleChecked({variables: { id: props.item.id }})
                }}
                disabled={loading} />
            <label className="form-check-label" htmlFor={props.item.id}>
                {label}
            </label>
        </div>
    );
}
