"use strict";

import React, { useState } from "react";
import FoodSearch from "../food/_search";
import IngredientWidget from "./_ingredient_widget";

export default function IngredientsWidget(props) {
    const [ingredients, setIngredients] = useState([]);

    // Helper function to avoid duplicate ingredients.
    const addFood = (food) => {
        let add = true;
        ingredients.forEach((ingredient) => {
            if (food.id == ingredient.food.id) {
                add = false;
            }
        });
        if (add) {
            props.onChange([...ingredients, { food: { name: food.name, id: food.id }, quantity: { type: food.unit_type } }]);
            setIngredients([...ingredients, { food: { name: food.name, id: food.id }, quantity: { type: food.unit_type } }]);
        }
    }

    const updateIngredient = (ingredient) => {
        ingredients.forEach((element, index, ingredients) => {
            if (element.food.id == ingredient.food) {
                ingredients[index].quantity = ingredient.quantity;
            }
        });
        props.onChange(ingredients);
        setIngredients(ingredients);
    }

    return (
        <div>
            <FoodSearch onChange={addFood} />
            {ingredients.map(({ food, quantity }) => (
                <IngredientWidget
                    key={food.id}
                    food={food.id}
                    name={food.name}
                    unit_type={quantity.type}
                    onChange={updateIngredient} />
            ))}
        </div>
    );
}
