"use strict";

import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import nl2br from "react-nl2br";

export default function MealLabel(props) {
    const [show, setShow] = useState(false);
    return (
        <>
            <a href="#" onClick={(e) => { e.preventDefault(); setShow(true); }} className="text-decoration-none text-body">
                {props.name}
            </a>
            <Modal show={show} onHide={(e) => setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{props.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <dl className="row">
                        <dt className="col-sm-3">Servings</dt>
                        <dd className="col-sm-9">{props.servings}</dd>

                        <dt className="col-sm-3">Preparation Time</dt>
                        <dd className="col-sm-9">{props.preparation_time} minutes</dd>

                        <dt className="col-sm-3">Ingredients</dt>
                        <dd className="col-sm-9">
                            <ul>
                                {props.ingredients.map((ingredient) => (
                                    <li key={ingredient.id}>{ingredient.quantity.amount} {ingredient.quantity.unit} {ingredient.food.name}</li>
                                ))}
                            </ul>
                        </dd>

                        <dt className="col-sm-3">Directions</dt>
                        <dd className="col-sm-9">{nl2br(props.directions)}</dd>
                    </dl>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => setShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
