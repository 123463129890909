"use strict";

import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import NutritionsDisplay from "../nutrition/_display";

function FoodLabel({id, name, nutrition, category}) {
    const [show, setShow] = useState(false);
    return (
        <>
            <a href="#" onClick={(e) => { e.preventDefault(); setShow(true); }} className="text-decoration-none text-body">
                {name}
            </a>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NutritionsDisplay nutritions={nutrition} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

FoodLabel.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    nutrition: PropTypes.arrayOf(PropTypes.object),
    category: PropTypes.object
};

export default FoodLabel;
