"use strict";

import React from "react";
import Badge from "react-bootstrap/Badge";
import MealPlanRemove from "./_remove";
import MealPlanItemAdd from "../meal_plan_item/_add";
import MealPlanItemCard from "../meal_plan_item/_card";

export default function MealPlan(props) {
    const date = new Date(props.date);
    let items = props.items.slice();
    items.sort((a, b) => {
        const [a_date, b_date] = [new Date(a.date_time), new Date(b.date_time)];
        return a_date.getTime() - b_date.getTime();
    });

    let badge = null;
    if (props.group != null) {
        badge = (<Badge bg="secondary">{props.group.name}</Badge>);
    }

    return (
        <div className="col-md-3">
            <div className="mb-3">
                <h5><MealPlanRemove id={props.id} /> {date.toDateString()}</h5>
                {badge}
            </div>
            <div className="mb-3">
                <MealPlanItemAdd id={props.id} date={date.toDateString()} />
            </div>
            {items.map((item) => (
                <div className="mb-3" key={item.id}>
                    <MealPlanItemCard id={item.id} type={item.type} meal={item.meal} />
                </div>
            ))}
        </div>
    );
}
