"use strict";

import React from "react";
import { Helmet } from "react-helmet";
import Foods from "../components/food";
import NavBarComponent from "../components/generic/_navbar";

export default function FoodPage() {
    return (
        <>
            <Helmet>
                <title>Food</title>
            </Helmet>
            <NavBarComponent />
            <div className="container">
                <Foods />
            </div>
        </>
    );
}
