"use strict";

import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";

export default function NavBarComponent() {
    return (
        <Navbar bg="light" expand="md">
            <Container>
                <Navbar.Brand href="/">Meal Planner</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="me-auto">
                        <NavLink to={`/meal-plan/`} className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}>Meal Plans</NavLink>
                        <NavLink to={`/meal/`} className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}>Meals</NavLink>
                        <NavLink to={`/food/`} className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}>Foods</NavLink>
                        <NavLink to={`/grocery-list/`} className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}>Grocery Lists</NavLink>
                    </Nav>
                    <Nav>
                        <Nav.Link href="/logout">Logout <i className="bi bi-box-arrow-right"></i></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

