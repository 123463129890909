"use strict";

import React from "react";
import { gql, useMutation } from "@apollo/client";

export default function MealRemove(props) {
    const [removeMeal] = useMutation(gql`
        mutation removeMeal($id: ID!) {
            removeMeal(id: $id)
        }
    `, {
        update(cache, data) {
            const normalizedId = cache.identify({ id: data.data.removeMeal, __typename: 'Meal' });
            cache.evict({ id: normalizedId });
            cache.gc();
        }
    });

    const handleRemove = (e) => {
        e.preventDefault();
        if (confirm('Are you sure you want to delete this meal?')) {
            removeMeal({ variables: { id: props.id } });
        }
    };

    return (
        <a href="#" className="badge rounded-pill text-bg-danger me-2" onClick={handleRemove}>
            <i className="bi bi-trash"></i>
        </a>
    );
}
