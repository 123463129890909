"use strict"

import React, { useState } from "react";
import QuantityWidget from "../quantity/_widget";

export default function IngredientWidget(props) {
    const [quantity, setQuantity] = useState({});

    const onChangeUnit = (e) => {
        quantity.unit = e;
        setQuantity(quantity);
        props.onChange({ food: props.food, quantity: quantity });
    }
    const onChangeAmount = (e) => {
        quantity.amount = Number(e.target.value);
        setQuantity(quantity);
        props.onChange({ food: props.food, quantity: quantity });
    }
    const onChangeType = (e) => {
        quantity.type = e;
        setQuantity(quantity);
        props.onChange({ food: props.food, quantity: quantity });
    }
    return (
        <div className="card mt-3">
            <div className="card-body">{props.name}</div>
            <div className="ps-3 pe-3">
                <QuantityWidget
                    unit_type={props.unit_type}
                    onChangeUnit={onChangeUnit}
                    onChangeType={onChangeType}
                    onChangeAmount={onChangeAmount} />
            </div>
        </div>
    );
}
