"use strict";

import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import IngredientsWidget from "../ingredient/_ingredients_widget";
import MealTypeSearch from "../meal_type/_search";

export default function MealAdd() {
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [types, setTypes] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [directions, setDirections] = useState("");
    const [preparation_time, setPreparationTime] = useState(0);
    const [servings, setServings] = useState(0);

    const [createMeal, { loading, error }] = useMutation(gql`
        mutation createMeal(
            $meal: MealInput!,
            $meal_types: [ID!],
            $ingredients: [IngredientInput!]
        ) {
            createMeal(
                meal: $meal,
                meal_types: $meal_types,
                ingredients: $ingredients
            ) {
                id
                name
                types {
                    id
                    name
                    color
                }
            }
        }`, {
            update(cache, { data: { createMeal } }) {
                cache.modify({
                    fields: {
                        meals(existingMeals = []) {
                            const newMealRef = cache.writeFragment({
                                data: createMeal,
                                fragment: gql`
                                    fragment NewMeal on Meal {
                                        id
                                        name
                                    }
                                `
                            });
                            return [...existingMeals, newMealRef];
                        }
                    }
                }
            );
        }
    });

    if (loading) return 'Submitting...';
    if (error) return `Submission error! ${error.message}`;

    const handleSubmit = (e) => {
        e.preventDefault();
        createMeal({
            variables: {
                meal: {
                    name: name,
                    directions: directions,
                    preparation_time: Number(preparation_time),
                    servings: Number(servings)
                },
                meal_types: types,
                ingredients: ingredients.map(({ food, quantity}) => ({ food: food.id, quantity: quantity })),
            }
        });
        setName("");
        setTypes([]);
        setIngredients([]);
        setDirections("");
        setPreparationTime(0);
        setServings(0);
        setShow(false);
    }

    return (
        <>
            <Button variant="primary" size="sm" onClick={() => setShow(true)}>
                <i className="bi bi-plus"></i><span hidden={true}> Add new Meal</span>
            </Button>
            <Offcanvas show={show} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Meal</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="meal-add" className="form-label">Name</label>
                            <input className="form-control" id="meal-add" onChange={(e) => setName(e.target.value)} required={true} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="type" className="form-label">Type(s)</label>
                            <MealTypeSearch multi={true} onChange={(e) => setTypes(e.map(({ value }) => value))} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="directions" className="form-label">Directions</label>
                            <textarea className="form-control" id="directions" rows="3" onChange={(e) => setDirections(e.target.value)}></textarea>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="preparation-time" className="form-label">Preparation time (mins)</label>
                            <input type="number" className="form-control" id="preparation-time" onChange={(e) => setPreparationTime(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="servings" className="form-label">Servings</label>
                            <input type="number" className="form-control" id="servings" onChange={(e) => setServings(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ingredients" className="form-label">Ingredients</label>
                            <IngredientsWidget onChange={(ingredients) => setIngredients(ingredients)} />
                        </div>
                        <button className="btn btn-primary" type="submit">Add</button>
                    </form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
