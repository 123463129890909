"use strict";

import React from "react";
import FoodAdd from "./_add";
import FoodImport from "./_import";
import FoodTable from "./_table";

export default function Foods() {
    return (
        <>
            <h1>
                <FoodAdd /><FoodImport /> Foods
            </h1>
            <FoodTable />
        </>
    );
}
