"use strict";

import React from "react";
import MealAdd from "./_add";
import MealTable from "./_table";

export default function Meals() {
    return (
        <>
            <h1>
                <MealAdd /> Meals
            </h1>
            <MealTable />
        </>
    );
}
