"use strict";

import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import FoodCategorySearch from "../food_category/_search";
import QuantityWidget from "../quantity/_widget";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import errorHandler from "../../util/error_handler";

export default function FoodAdd() {
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [category, setCategory] = useState("");
    const [quantity_amount, setQuantityAmount] = useState(0.0);
    const [quantity_unit, setQuantityUnit] = useState("");
    const [quantity_type, setQuantityType] = useState("");
    const [nutrition_calories, setNutritionCalories] = useState(0);
    const [nutrition_fat, setNutritionFat] = useState(0.0);
    const [nutrition_sugar, setNutritionSugar] = useState(0.0);
    const [nutrition_carbohydrates, setNutritionCarbohydrates] = useState(0.0);
    const [nutrition_protein, setNutritionProtein] = useState(0.0);
    const [nutrition_fibre, setNutritionFibre] = useState(0.0);

    const [createFood, { loading, error }] = useMutation(gql`
        mutation createFood($food: FoodInput!, $nutritions: [NutritionInput!]) {
            createFood(food: $food, nutritions: $nutritions) { id }
        }`, { refetchQueries: ['foods'] }
    );

    if (loading) return 'Submitting...';
    if (error) return errorHandler(error);

    const handleSubmit = (e) => {
        e.preventDefault();
        createFood({
            variables: {
                food: {
                    name: name,
                    category: category
                },
                nutritions: [
                    {
                        calories: nutrition_calories,
                        fat: nutrition_fat,
                        sugar: nutrition_sugar,
                        carbohydrates: nutrition_carbohydrates,
                        protein: nutrition_protein,
                        firbre: nutrition_fibre,
                        quantity: {
                            amount: quantity_amount,
                            unit: quantity_unit,
                            type: quantity_type
                        }
                    }
                ]
            }
        });
        setName("");
        setCategory("");
        setQuantityAmount(0.0);
        setQuantityUnit("");
        setQuantityType("");
        setNutritionCalories(0);
        setNutritionFat(0.0);
        setNutritionSugar(0.0);
        setNutritionCarbohydrates(0.0);
        setNutritionProtein(0.0);
        setNutritionFibre(0.0);
        setShow(false);
    };

    return (
        <>
            <Button variant="primary" size="sm" onClick={() => setShow(true)}>
                <i className="bi bi-plus"></i><span hidden={true}> Add new Food</span>
            </Button>
            <Offcanvas show={show} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Food</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <form
                        onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="food-add" className="form-label">Name</label>
                            <input className="form-control" id="food-add" onChange={(e) => setName(e.target.value)} required={true} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="category" className="form-label">Category</label>
                            <FoodCategorySearch onChange={(e) => setCategory(e?.value || null)} />
                        </div>
                        <label className="form-label" htmlFor="quantity-amount">Quantity</label>
                        <QuantityWidget
                            onChangeAmount={(e) => setQuantityAmount(parseFloat(e.target.value))}
                            onChangeUnit={(e) => setQuantityUnit(e)}
                            onChangeType={(e) => setQuantityType(e)} />
                        <div className="mb-3">
                            <label htmlFor="calories" className="form-label">Calories</label>
                            <input className="form-control" id="calories" onChange={(e) => setNutritionCalories(Number(e.target.value))} required={true} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="fat" className="form-label">Fat</label>
                            <input type="number" step="0.01" className="form-control" id="fat" onChange={(e) => setNutritionFat(parseFloat(e.target.value))} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sugar" className="form-label">Sugar</label>
                            <input type="number" step="0.01" className="form-control" id="sugar" onChange={(e) => setNutritionSugar(parseFloat(e.target.value))} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="carbohydrates" className="form-label">Carbohydrates</label>
                            <input type="number" step="0.01" className="form-control" id="carbohydrates" onChange={(e) => setNutritionCarbohydrates(parseFloat(e.target.value))} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="protein" className="form-label">Protein</label>
                            <input type="number" step="0.01" className="form-control" id="protein" onChange={(e) => setNutritionProtein(parseFloat(e.target.value))} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="fibre" className="form-label">Fibre</label>
                            <input type="number" step="0.01" className="form-control" id="fibre" onChange={(e) => setNutritionFibre(parseFloat(e.target.value))} />
                        </div>
                        <button className="btn btn-primary" type="submit">Add</button>
                    </form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
