"use strict";

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import nl2br from "react-nl2br";
import MealPlanItemRemove from "./_remove";

export default function MealPlanItemCard(props) {
    const [show, setShow] = useState(false);
    const [meal, type, id] = [props.meal, props.type, props.id];

    return (
        <>
            <a href="#" onClick={(e) => { e.preventDefault(); setShow(true); }} className="card text-body text-decoration-none shadow-sm">
                <div className="card-body">
                    <p><span className={"me-2 badge rounded-pill bg-" + type.color}>{type.name}</span></p>
                    {meal.name}
                </div>
            </a>
            <Modal show={show} onHide={(e) => setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{meal.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <dl className="row">
                        <dt className="col-sm-3">Servings</dt>
                        <dd className="col-sm-9">{meal.servings}</dd>

                        <dt className="col-sm-3">Preparation Time</dt>
                        <dd className="col-sm-9">{meal.preparation_time} minutes</dd>

                        <dt className="col-sm-3">Ingredients</dt>
                        <dd className="col-sm-9">
                            <ul>
                                {meal.ingredients.map((ingredient) => (
                                    <li key={ingredient.id}>{ingredient.quantity.amount} {ingredient.quantity.unit} {ingredient.food.name}</li>
                                ))}
                            </ul>
                        </dd>

                        <dt className="col-sm-3">Directions</dt>
                        <dd className="col-sm-9">{nl2br(meal.directions)}</dd>
                    </dl>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => setShow(false)}>
                        Close
                    </Button>
                    <MealPlanItemRemove id={id} onRemove={(e) => setShow(false)} />
                </Modal.Footer>
            </Modal>
        </>
    );
}
