"use strict";

import React from "react";
import { gql, useMutation } from "@apollo/client";

export default function GroceryListRemove(props) {
    const [removeGroceryList] = useMutation(gql`mutation removeGroceryList($id: ID!) {
        removeGroceryList(id: $id)
    }`,
    {
        update(cache, data) {
            const normalizedId = cache.identify({ id: data.data.removeGroceryList, __typename: 'GroceryList' });
            cache.evict({ id: normalizedId });
            cache.gc();
        }
    });

    const handleRemove = (e) => {
        e.preventDefault();
        if (confirm('Are you sure you want to delete this grocery list?')) {
            removeGroceryList({ variables: { id: props.id } });
        }
    };

    return (
        <a href="#" className="badge rounded-pill text-bg-danger" onClick={handleRemove}>
            <i className="bi bi-trash"></i>
        </a>
    );
}
