"use strict";

import { gql } from "@apollo/client";
import { food_client } from "../../util/client";
import { debounce } from "debounce";
import React, { useCallback, useState } from "react";
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

function FoodSearchRemote({ onChange }) {
    const [search, setSearch] = useState("");
    const getAsyncOptions = async (input) => {
        return food_client.query({
            query: gql`
                query remoteFoods($search: String, $limit: Int) {
                    foods(search: $search, limit: $limit) {
                        id
                        description
                    }
                }
            `, variables: { search: input, limit: 50 }
        }).then((response) => {
            return response.data.foods.map((food) => ({
                value: food.id,
                label: food.description,
            }));
        });
    };

    const loadOptions = useCallback(debounce((inputText, callback) => {
        getAsyncOptions(inputText).then((options) => callback(options));
    }, 500), []);

    return (
        <AsyncSelect
            inputValue={search}
            onInputChange={(value) => setSearch(value)}
            loadOptions={loadOptions}
            defaultValue={[]}
            defaultOptions={[]}
            onChange={onChange} />
    );
}

FoodSearchRemote.propTypes = {
    onChange: PropTypes.func.isRequired
}

export default FoodSearchRemote;
