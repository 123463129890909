"use strict";

import { ApolloClient, InMemoryCache } from "@apollo/client";

export const client = new ApolloClient({
    uri: "/graphql",
    cache: new InMemoryCache(),
});

export const food_client = new ApolloClient({
    uri: "https://nutrition.forestcitylabs.ca/graphql",
    cache: new InMemoryCache(),
});
