"use strict";

import React from "react";
import MealPlanAdd from "./_add";
import MealPlanDisplay from "./_plans";

export default function MealPlans() {
    return (
        <>
            <h1><MealPlanAdd /> Meal Plans</h1>
            <MealPlanDisplay />
        </>
    );
}
