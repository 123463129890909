"use strict";

import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { food_client } from "../../util/client";
import FoodCategorySearch from "../food_category/_search";
import QuantityWidget from "../quantity/_widget";
import FoodSearchRemote from "./_search_remote";

export default function FoodImport() {
    const [show, setShow] = useState(false);
    const [food, setFood] = useState(0);
    const [type, setType] = useState("");
    const [unit, setUnit] = useState("");
    const [category, setCategory] = useState("");
    const [amount, setAmount] = useState(0.0);

    const [createFood, { loading, error }] = useMutation(gql`
        mutation createFood($food: FoodInput!, $nutritions: [NutritionInput!]) {
            createFood(food: $food, nutritions: $nutritions) { id }
        }`, { refetchQueries: ['foods'] }
    );

    if (loading) return 'Submitting...';
    if (error) return errorHandler(error);

    const handleSubmit = (e) => {
        e.preventDefault();
        food_client.query({
            query: gql`
                query food ($id: ID!, $quantity: QuantityInput!) {
                    food (id: $id) {
                        description
                        nutritionFor(quantity: $quantity) {
                            calories
                            fat
                            sugar
                            carbohydrates
                            protein
                            fibre
                        }
                    }
                }
            `,
            variables: {
                id: food,
                quantity: {
                    type: type,
                    unit: unit,
                    amount: amount
                }
            }
        }).then((response) => {
            createFood({
                variables: {
                    food: {
                        name: response.data.food.description,
                        category: category
                    },
                    nutritions: [
                        {
                            calories: response.data.food.nutritionFor.calories,
                            fat: response.data.food.nutritionFor.fat,
                            sugar: response.data.food.nutritionFor.sugar,
                            carbohydrates: response.data.food.nutritionFor.carbohydrates,
                            protein: response.data.food.nutritionFor.protein,
                            fibre: response.data.food.nutritionFor.fibre,
                            quantity: {
                                amount: amount,
                                unit: unit,
                                type: type
                            }
                        }
                    ]
                }
            });
            setAmount(0.0);
            setCategory("");
            setUnit("");
            setType("");
            setFood(0);
            setShow(false);
        });
    };
    return (
        <>
            <Button variant="primary" size="sm" onClick={() => setShow(true)} className="ms-2">
                <i className="bi bi-download"></i><span hidden={true}> Import Food</span>
            </Button>
            <Offcanvas show={show} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Import Food</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="food-search-remote" className="form-label">Search</label>
                            <FoodSearchRemote onChange={(e) => setFood(Number(e.value))} />
                            <Form.Text className="text-muted">
                                Food data sourced from the <a href="https://www.canada.ca/en/health-canada/services/food-nutrition/healthy-eating/nutrient-data/canadian-nutrient-file-about-us.html" target="_blank">Canadian Nutrient File</a>.
                            </Form.Text>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="category" className="form-label">Category</label>
                            <FoodCategorySearch onChange={(e) => setCategory(e.value)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="quantity-amount">Quantity</label>
                            <QuantityWidget
                                onChangeUnit={(unit) => setUnit(unit)}
                                onChangeAmount={(amount) => setAmount(parseFloat(amount.target.value))}
                                onChangeType={(type) => setType(type)} />
                        </div>
                        <Button type="submit" variant="primary">Import Food</Button>
                    </form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
