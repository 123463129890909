"use strict";

import { gql } from "@apollo/client";
import { client } from "../../util/client";
import { debounce } from "debounce";
import React, { useCallback, useState } from "react";
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

function FoodSearch({ onChange }) {
    const [search, setSearch] = useState("");

    const getAsyncOptions = async (input) => {
        return client.query({
            query: gql`
                query foods($search: String, $max_results: Int) {
                    foods(search: $search, max_results: $max_results) {
                        id
                        name
                        nutrition {
                            id
                            quantity {
                                id
                                type
                            }
                        }
                    }
                }
            `, variables: { search: input, max_results: 5 }
        }).then((response) => {
            return response.data.foods.map((food) => ({
                value: food.id,
                label: food.name,
                id: food.id,
                name: food.name,
                unit_type: food.nutrition[0].quantity.type
            }));
        });
    };

    const loadOptions = useCallback(debounce((inputText, callback) => {
        getAsyncOptions(inputText).then((options) => callback(options));
    }, 500), []);

    return (
        <AsyncSelect
            inputValue={search}
            onInputChange={(value) => setSearch(value)}
            loadOptions={loadOptions}
            defaultValue={[]}
            defaultOptions={[]}
            onChange={onChange} />
    );
}

FoodSearch.propTypes = {
    name: PropTypes.func.isRequired
}

export default FoodSearch;
