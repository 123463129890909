"use strict";

import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import GroupSelect from "../group/_select";

export default function GroceryListAdd() {
    const [show, setShow] = useState(false);
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [group, setGroup] = useState(null);

    const [generateGroceryList, {loading, error}] = useMutation(gql`
        mutation generateGroceryList($grocery_list: GroceryListInput!) {
            generateGroceryList(grocery_list: $grocery_list) { id }
        }
    `, { refetchQueries: ['groceryLists'] });

    return (
        <>
            <Button variant="primary" size="sm" onClick={() => setShow(true)}>
                <i className="bi bi-arrow-clockwise"></i><span hidden={true}> Generate Grocery List</span>
            </Button>
            <Offcanvas show={show} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Generate Grocery List</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <form className="row" onSubmit={(e) => {
                        e.preventDefault();
                        generateGroceryList({ variables: {
                            grocery_list: {
                                group: group,
                                start_date: start_date,
                                end_date: end_date
                            }
                        }});
                        setStartDate("");
                        setEndDate("");
                        setGroup(null);
                        setShow(false);
                    }}>
                        <div className="mb-3">
                            <label htmlFor="start_date" className="form-label">Start Date</label>
                            <input type="date" className="form-control" id="start_date" onChange={(e) => {
                                let tmp_date = new Date(e.target.value);
                                tmp_date.setMinutes(tmp_date.getMinutes() + tmp_date.getTimezoneOffset());
                                setStartDate(tmp_date.toISOString());
                            }} required={true} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="end_date" className="form-label">End Date</label>
                            <input type="date" className="form-control" id="end_date" onChange={(e) => {
                                let tmp_date = new Date(e.target.value);
                                tmp_date.setDate(tmp_date.getDate() + 1);
                                tmp_date.setMinutes(tmp_date.getMinutes() + tmp_date.getTimezoneOffset());
                                setEndDate(tmp_date.toISOString());
                            }} required={true} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="group-select" className="form-label">Group</label>
                            <GroupSelect id="group-select" onChange={(e) => setGroup(e.value)} />
                        </div>
                        <div className="mb-3">
                            <button className="btn btn-primary" type="submit">Generate Grocery List</button>
                        </div>
                    </form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
