"use strict";

import React from "react";
import { Helmet } from "react-helmet";

export default function NotFound() {
    return (
        <div className="container-fluid text-center">
            <h1 className="display-1">404</h1>
            <p className="text-muted">Not found</p>
            <Helmet>
                <title>404 - Not Found</title>
            </Helmet>
        </div>
    );
}
