"use strict";

export const unit_map = {
    mass: [
        { name: 'kilogram', value: 'kg' },
        { name: 'gram', value: 'g' },
        { name: 'pound', value: 'lb' },
        { name: 'ounce', value: 'oz' },
    ],
    volume: [
        { name: 'milliliter', value: 'ml' },
        { name: 'liter', value: 'l' },
        { name: 'cup', value: 'cup' },
        { name: 'teaspoon', value: 'tsp' },
        { name: 'tablespoon', value: 'tbsp' },
        { name: 'fluid ounce', value: 'fl oz' },
    ],
    quantity: [
        { name: 'item', value: 'mol' }
    ],
};
