"use strict";

import React from "react";
import { DebounceInput } from "react-debounce-input";
import PropTypes from 'prop-types';

function Search({ refetch, id, placeholder }) {
    return (
        <DebounceInput
            minLength={3}
            debounceTimeout={300}
            onChange={(e) => refetch({ search: e.target.value })}
            className="form-control"
            id={id || 'search'}
            placeholder={placeholder || 'Search'} />
    );
}

Search.propTypes = {
    refetch: PropTypes.func.isRequired,
    id: PropTypes.string,
    placeholder: PropTypes.string
}

export default Search;
