"use strict";

import React from "react";
import PropTypes from 'prop-types';
import Search from "../generic/_search";
import FoodCategorySearch from "../food_category/_search";

function FoodFilter({ refetch }) {
    return (
        <div className="row">
            <div className="col">
                <Search refetch={refetch} />
            </div>
            <div className="col">
                <FoodCategorySearch onChange={(e) => refetch({ category: e?.value || null })} />
            </div>
        </div>
    );
}

FoodFilter.propTypes = {
   refetch: PropTypes.func.isRequired
};

export default FoodFilter;
