"use strict";

import React from "react";

export default function errorHandler(error) {
    if (error.graphQLErrors[0].extensions.category  == "security") {
        window.location.replace("/logout");
        return null;
    } else {
        return error.message;
    }
}
