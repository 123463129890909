"use strict";

import React from "react";
import { Helmet } from "react-helmet";
import NavBarComponent from "../components/generic/_navbar";
import Meals from "../components/meal";

export default function MealPage() {
    return (
        <>
            <Helmet>
                <title>Meals</title>
            </Helmet>
            <NavBarComponent />
            <div className="container">
                <Meals />
            </div>
        </>
    );
}
