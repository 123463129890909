"use strict";

import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import MealSelect from "../meal/_select";
import MealTypeSearch from "../meal_type/_search";

export default function MealPlanItemAdd(props) {
    const [open, setOpen] = useState(false);
    const [meal, setMeal] = useState("");
    const [time, setTime] = useState("");
    const [servings, setServings] = useState(0);
    const [type, setType] = useState("");

    const [createMealPlanItem, { loading, error }] = useMutation(gql`
        mutation createMealPlanItem(
            $meal_id: ID!,
            $meal_plan_id: ID!,
            $meal_type_id: ID!,
            $meal_plan_item: MealPlanItemInput!
        ) {
            createMealPlanItem(
                meal_id: $meal_id,
                meal_plan_id: $meal_plan_id,
                meal_type_id: $meal_type_id,
                meal_plan_item: $meal_plan_item
            ) {
                id
                type {
                    id
                    name
                }
                date_time
                meal {
                    id
                    name
                }
                meal_plan {
                    id
                }
            }
        }`, { refetchQueries: [ 'mealPlans' ] });

    if (loading) return 'Submitting...';
    if (error) return `Submission error! ${error.message}`;

    const handleSubmit = (e) => {
        e.preventDefault();
        createMealPlanItem({
            variables: {
                meal_plan_item: {
                    date_time: props.date + ' ' + time,
                    servings: servings
                },
                meal_type_id: type,
                meal_plan_id: props.id,
                meal_id: meal
            }
        });
        setType("");
        setMeal("");
        setTime("");
        setServings(0);
    }

    return (
        <>
            <form className="card" onSubmit={handleSubmit}>
                <div className="card-body">
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        size="sm">
                        <i className="bi bi-plus"></i> Add item
                    </Button>
                    <Collapse in={open}>
                        <div className="mt-3" id={'item-add-' + props.id}>
                            <div className="mb-3">
                                <label htmlFor="type" className="form-label">Type</label>
                                <MealTypeSearch multi={false} onChange={(e) => setType(e.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="item-time" className="form-label">Time</label>
                                <input type="time" className="form-control" id="item-time" onChange={(e) => setTime(e.target.value)} required={true} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="item-servings" className="form-label">Servings</label>
                                <input type="number" className="form-control" id="item-servings" onChange={(e) => setServings(new Number(e.target.value))} required={true} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Meal</label>
                                <MealSelect onChange={(value) => setMeal(value.value)} />
                            </div>
                            <button className="btn btn-primary" type="submit">Add</button>
                        </div>
                    </Collapse>
                </div>
            </form>
        </>
    );
}
