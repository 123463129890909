"use strict";

import React from "react";
import { gql, useMutation } from "@apollo/client";

export default function MealPlanRemove(props) {
    const [removeMealPlan] = useMutation(gql`mutation removeMealPlan($id: ID!) {
        removeMealPlan(id: $id)
    }`,
    {
        update(cache, data) {
            const normalizedId = cache.identify({ id: data.data.removeMealPlan, __typename: 'MealPlan' });
            cache.evict({ id: normalizedId });
            cache.gc();
        }
    });

    const handleRemove = (e) => {
        e.preventDefault();
        if (confirm('Are you sure you want to delete this meal plan?')) {
            removeMealPlan({ variables: { id: props.id } });
        }
    };

    return (
        <a href="#" className="badge rounded-pill text-bg-danger" onClick={handleRemove}>
            <i className="bi bi-trash"></i>
        </a>
    );
}
