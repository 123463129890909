"use strict";

import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import GroupSelect from "../group/_select";

export default function MealPlanAdd() {
    const [show, setShow] = useState(false);
    const [date, setDate] = useState("");
    const [group, setGroup] = useState("");

    const [createMealPlan, { loading, error }] = useMutation(gql`
        mutation createMealPlan($meal_plan: MealPlanInput!) {
            createMealPlan(meal_plan: $meal_plan) {
                id
                date
                meals {
                    id
                    meal {
                        id
                        name
                    }
                }
            }
        }`, {
            update(cache, { data: { createMealPlan } }) {
                cache.modify({
                    fields: {
                        mealPlans(existingMealPlans = []) {
                            const newMealPlanRef = cache.writeFragment({
                                data: createMealPlan,
                                fragment: gql`
                                    fragment NewMealPlan on MealPlan {
                                        id
                                        name
                                    }
                                `
                            });
                            return [...existingMealPlans, newMealPlanRef];
                        }
                    }
                }
            );
        }
    });

    if (loading) return 'Submitting...';
    if (error) return `Submission error! ${error.message}`;

    return (
        <>
            <Button variant="primary" size="sm" onClick={() => setShow(true)}>
                <i className="bi bi-plus"></i><span hidden={true}> Add new Meal</span>
            </Button>
            <Offcanvas show={show} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Meal Plan</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <form className="row" onSubmit={(e) => {
                        e.preventDefault();
                        createMealPlan({ variables: { meal_plan: { date: date, group: group }}});
                        setDate("");
                        setGroup("");
                        setShow(false);
                    }}>
                        <div className="mb-3">
                            <label htmlFor="date" className="form-label">Date</label>
                            <input type="date" className="form-control" id="date" onChange={(e) => {
                                const tmp_date = new Date(e.target.value);
                                tmp_date.setMinutes(tmp_date.getMinutes() + tmp_date.getTimezoneOffset());
                                setDate(tmp_date.toISOString());
                            }} required={true} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="group-select" className="form-label">Group</label>
                            <GroupSelect id="group-select" onChange={(e) => setGroup(e.value)} />
                        </div>
                        <div className="mb-3">
                            <button className="btn btn-primary" type="submit">Add Meal Plan</button>
                        </div>
                    </form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
