"use strict";

import { gql, useQuery } from "@apollo/client";
import React from "react";
import MealPlan from "./_plan";
import errorHandler from "../../util/error_handler";

export default function MealPlanDisplay() {
    const { data, loading, error } = useQuery(gql`
        query mealPlans($start_date: String) {
            mealPlans(start_date: $start_date) {
                id
                date
                group {
                    id
                    name
                }
                meals {
                    id
                    date_time
                    type {
                        id
                        name
                        color
                    }
                    meal {
                        id
                        name
                        preparation_time
                        directions
                        servings
                        ingredients {
                            id
                            quantity {
                                id
                                unit
                                amount
                            }
                            food {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    `);

    if (loading) return <p>Loading...</p>;
    if (error) return errorHandler(error);

    return (
        <div className="table-responsive">
            <div className="row flex-nowrap">
                {data.mealPlans.map((meal_plan) => <MealPlan
                    key={meal_plan.id}
                    id={meal_plan.id}
                    date={meal_plan.date}
                    items={meal_plan.meals}
                    group={meal_plan.group} />)}
            </div>
        </div>
    );
}
