"use strict";

import { gql, useQuery } from "@apollo/client";
import React from "react";
import errorHandler from "../../util/error_handler";
import GroceryListAdd from "./_add";
import GroceryList from "./_list";

export default function GroceryLists() {
    const { loading, error, data } = useQuery(gql`
        query groceryLists {
            groceryLists {
                id
                start_date
                end_date
                group {
                    id
                    name
                }
                items {
                    id
                    checked
                    auto_added
                    food {
                        id
                        name
                        category {
                            id
                            name
                        }
                    }
                    quantity {
                        id
                        amount
                        type
                        unit
                    }
                }
            }
        }
    `);

    if (loading) return 'Loading...';
    if (error) return errorHandler(error);

    return (
        <>
            <h1><GroceryListAdd /> Grocery Lists</h1>
            {data.groceryLists.map((list) => (
                <div className="col-md-4" key={list.id}>
                    <GroceryList list={list} />
                </div>
            ))}
        </>
    );
}
