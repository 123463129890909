"use strict";

import React, { Fragment } from "react";
import Badge from "react-bootstrap/Badge";
import GroceryListItem from "../grocery_list_item/_item";
import GroceryListRemove from "./_remove";

export default function GroceryList(props) {
    let items = props.list.items.slice();
    items.sort((a, b) => {
        if (a.food.category.name > b.food.category.name) {
            return 1;
        }
        if (a.food.category.name < b.food.category.name) {
            return -1;
        }
        return 0;
    });
    let category = null;
    const [start_date, end_date] = [new Date(props.list.start_date), new Date(props.list.end_date)];

    let badge = null;
    if (props.list.group != null) {
        badge = (<Badge bg="secondary" className="mb-3">{props.list.group.name}</Badge>);
    }

    return (
        <div className="mb-3">
            <h5><GroceryListRemove id={props.list.id} /> {start_date.toDateString()} to {end_date.toDateString()}</h5>
            {badge}
            <div className="card">
                <div className="card-body">
                    {items.map((item) => {
                        if (category != item.food.category.name) {
                            category = item.food.category.name;
                            return (
                                <Fragment key={item.id}>
                                    <h6>{category}</h6>
                                    <GroceryListItem item={item} />
                                </Fragment>
                            );
                        }
                        return <GroceryListItem key={item.id} item={item} />
                    })}
                </div>
            </div>
        </div>
    );
}
